import './ComingSoon.css';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon-content">
        <Link to="/" className="back-home">← Back to Home</Link>
        <h1>Coming Soon</h1>
        <div className="countdown-wrapper">
          <div className="launch-text">Our App is Launching</div>
          <div className="geometric-shapes">
            <div className="shape"></div>
            <div className="shape"></div>
            <div className="shape"></div>
          </div>
        </div>
        <p className="description">
          We're crafting something special for you. Our mobile app will bring the beauty of handloom right to your fingertips.
        </p>
        <div className="notify-form">
          <input type="email" placeholder="Enter your email for updates" />
          <button>Notify Me</button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;