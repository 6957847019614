import './HandloomSection.css';
import { useEffect, useRef } from 'react';

const HandloomSection = () => {
  const sectionRef = useRef(null);
  const bgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    const handleScroll = () => {
      if (!sectionRef.current || !bgRef.current) return;

      const rect = sectionRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = rect.top + scrollTop;
      const scrollPosition = window.pageYOffset;

      const relativePos = scrollPosition - offsetTop;
      const parallaxSpeed = 0.5;

      bgRef.current.style.transform = `translateY(${relativePos * parallaxSpeed}px)`;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, []);

  return (
    <section className="handloom-section parallax-section" ref={sectionRef}>
      <div className="handloom-bg parallax-bg" ref={bgRef}></div>
      <div className="overlay"></div>
      <div className="handloom-content">
        <h2 className="section-title">The Art of Handloom</h2>
        <p className="section-subtitle">Each thread tells a story of tradition</p>
      </div>
    </section>
  );
};

export default HandloomSection;