import { useEffect, useRef } from 'react';
import './LocationContact.css';

const LocationContact = () => {
  const sectionRef = useRef(null);
  const bgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    const handleScroll = () => {
      if (!sectionRef.current || !bgRef.current) return;

      const rect = sectionRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = rect.top + scrollTop;
      const scrollPosition = window.pageYOffset;

      const relativePos = scrollPosition - offsetTop;
      const parallaxSpeed = 0.5;

      bgRef.current.style.transform = `translateY(${relativePos * parallaxSpeed}px)`;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, []);

  return (
    <section className="location-contact parallax-section" ref={sectionRef}>
      <div className="contact-bg parallax-bg" ref={bgRef}></div>
      <div className="overlay"></div>
      <div className="contact-content">
        <h2 className="section-title">Visit Us</h2>
        <div className="contact-info section-subtitle">
          <p>Geetika Handloom Building</p>
          <p>Near Harmoti Over Bridge, North Lakhimpur</p>
          <p>Whatsapp: +919101389580</p>
        </div>
      </div>
      <div className="map-overlay">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.6173997042783!2d93.87782046519249!3d27.122724427771654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x374153174f19b62b%3A0x82c41c745a55cd6f!2sGeetika%20Handloom!5e0!3m2!1sen!2sin!4v1732115553069!5m2!1sen!2sin"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default LocationContact;