import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/variables.css';
import './App.css';
import Hero from './components/Hero';
import HandloomSection from './components/HandloomSection';
import GamusaShowcase from './components/GamusaShowcase';
import LocationContact from './components/LocationContact';
import ComingSoon from './components/ComingSoon';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <Hero />
            <HandloomSection />
            <GamusaShowcase />
            <LocationContact />
          </div>
        } />
        <Route path="/app" element={<ComingSoon />} />
      </Routes>
    </Router>
  );
}

export default App;
