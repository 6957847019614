import { useEffect } from 'react';
import './Hero.css';

const Hero = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.pageYOffset;
      const parallaxBg = document.querySelector('.parallax-bg');
      if (parallaxBg) {
        parallaxBg.style.transform = `translateY(${scrolled * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="hero parallax-section">
      <div className="parallax-bg"></div>
      <div className="brand-name">
        <h2>Geetika Handloom</h2>
      </div>
      <div className="hero-bottom-content">
        <div className="hero-text">
          <h1>Weaving Traditions</h1>
          <p className="subtitle">Where Heritage Meets Elegance</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;